import {
  initI18n as initI18nWithICU,
  initI18nWithoutICU,
} from '@wix/yoshi-flow-editor';
import {
  createI18nLeanWithoutICU,
  createI18nLeanWithICU,
} from '@wix/communities-blog-client-common';

export const initI18nLean = (locale, translatinos, useICU = false) => {
  return useICU
    ? createI18nLeanWithICU(locale, translatinos, initI18nWithICU)
    : createI18nLeanWithoutICU(locale, translatinos, initI18nWithoutICU);
};
