import { icuOptions } from '../constants/icu-options';
var I18N_NESTING_KEY_SEPARATOR = '$';
export var createI18nLeanWithoutICU = function (locale, messages, initI18nWithoutICU) {
    var i18n = initI18nWithoutICU({ locale: locale, messages: messages });
    i18n.init({
        keySeparator: I18N_NESTING_KEY_SEPARATOR,
        interpolation: {
            escapeValue: false,
            format: format,
        },
    });
    return i18n;
};
export var createI18nLeanWithICU = function (locale, messages, initI18nWithICU) {
    var i18n = initI18nWithICU({ locale: locale, messages: messages, icuOptions: icuOptions });
    i18n.init();
    return i18n;
};
function format(value, _format, _lng, options) {
    if (options === void 0) { options = {}; }
    switch (_format) {
        case 'number':
            return typeof options.numberFormatted === 'string' ? options.numberFormatted : value;
        default:
            return value;
    }
}
